<template>
    <ModalWrapper ref="modal" custom-close transparent @overlay-click="close" @close-modal="$emit('close-modal')">
        <div class="active-operations-modal modal-window">
            <div class="modal-window__close" @click="close">
                <AppIcon icon="close"/>
            </div>
            <div v-if="defaultHeader" class="modal-window__header">
                <AppIcon :icon="modalIcon"/>
                {{ modalName }}
            </div>
            <div v-else class="active-operations-modal__header">{{ modalName }}</div>
            <form v-if="activeOperationsType === activeOperationsCodes.block || activeOperationsType === activeOperationsCodes.unblock" class="modal-window__body application-input-theme-login" @submit.prevent="activeOperationsSubmit">
                <CardName class="mb-9-a" :value="card.cardNumberMasked" :type="card.typeName"/>
                <div v-if="activeOperationsType === activeOperationsCodes.block" class="application-input-wrap mb-6-a">
                    <div class="application-input-title">{{ $t("components.common.reasonForBlocking") }}</div>
                    <AppSelect v-model="activeOperationsModel.reason" class="application-input" :error="activeOperationsErrors.reason" :items="blockReasons" size="large" #default="{ item, id }" @input="activeOperationsOnInput('reason')">
                        <a-select-option :value="id">
                            {{ item }}
                        </a-select-option>
                    </AppSelect>
                    <div v-if="activeOperationsErrors.reason" class="application-input-error">{{ $t("components.common.thisFieldIsRequired") }}</div>
                </div>
                <div class="application-input-wrap mb-6-a">
                    <div class="application-input-title">{{ $t("components.common.note") }}</div>
                    <AppTextarea v-model="activeOperationsModel.comment" class="application-input" :error="activeOperationsErrors.comment" size="large" :placeholder="$t('components.common.enterANote')" @input="activeOperationsOnInput('comment')"/>
                    <div v-if="activeOperationsErrors.comment" class="application-input-error">{{ $t("components.common.thisFieldIsRequired") }}</div>
                </div>
                <div class="mb-13-a">
                    <AppCheckbox v-model="activeOperationsModel.selected" :error="activeOperationsErrors.selected" @input="activeOperationsOnInput('selected')">{{ $t("components.common.iUnderstandTheConsequencesOfMyActions") }}</AppCheckbox>
                </div>
                <div class="d-flex justify-content-end">
                    <AppButton class="pl-6-a pr-6-a mr-4-a" type="button" theme="gray" tabindex="-1" @click="close">
                        {{ $t("common.cancel") }}
                    </AppButton>
                    <LoadingContent :loading="activeOperationsLoading">
                        <AppButton v-if="activeOperationsType === activeOperationsCodes.block" key="block" class="pl-6-a pr-6-a" type="submit" :disabled="!activeOperationsModel.selected" theme="danger-light">
                            {{ $t("components.common.block") }}
                        </AppButton>
                        <AppButton v-else class="pl-6-a pr-6-a" key="unblock" type="submit" :disabled="!activeOperationsModel.selected">
                            {{ $t("components.common.unblock") }}
                        </AppButton>
                    </LoadingContent>
                </div>
            </form>
            <form v-else-if="activeOperationsType === activeOperationsCodes.pin" class="modal-window__body application-input-theme-login" @submit.prevent="activeOperationsSubmit">
                <CardName class="mb-8-a" :value="card.cardNumberMasked" :type="card.typeName"/>
                <div class="active-operations-modal__subtitle mb-13-a">{{ $t("components.common.areYouSureThatYouWantToResetTheNumberOfPinCode") }}</div>
                <div class="d-flex justify-content-center">
                    <AppButton class="pl-6-a pr-6-a mr-4-a" type="button" theme="gray" tabindex="-1" @click="close">
                        {{ $t("common.cancel") }}
                    </AppButton>
                    <LoadingContent :loading="activeOperationsLoading">
                        <AppButton class="pl-6-a pr-6-a" type="submit">
                            {{ $t("components.common.reset") }}
                        </AppButton>
                    </LoadingContent>
                </div>
            </form>
        </div>
    </ModalWrapper>
</template>
<script>
import AppIcon from "../partials/AppIcon";
import AppButton from "../partials/AppButton";
import AppTextarea from "../partials/AppTextarea";
import AppSelect from "../partials/AppSelect";
import ModalWrapper from "../partials/ModalWrapper";
import CardName from "./partials/CardName";
import AppCheckbox from "../partials/AppCheckbox";
import {activeOperationsCodes} from "../../store/modules/activeOperations";
import LoadingContent from "../partials/LoadingContent";
import {post} from "../../helpers/api";
import activeOperationsMixin from "../../mixins/activeOperationsMixin";

export default {
    name: 'ActiveOperationsModal',
    components: {LoadingContent, AppCheckbox, CardName, ModalWrapper, AppButton, AppIcon, AppTextarea, AppSelect},
    mixins: [
        activeOperationsMixin
    ],
    props: {
        type: String,
    },
    computed: {
        activeOperationsType() {
            return this.type;
        },
        modalIcon() {
            switch(this.activeOperationsType) {
                case this.activeOperationsCodes.block:
                    return 'lock-alt';
                case this.activeOperationsCodes.unblock:
                    return 'lock-open-alt'
            }
        },
        modalName() {
            switch(this.activeOperationsType) {
                case this.activeOperationsCodes.block:
                    return 'Блокировка карты';
                case this.activeOperationsCodes.unblock:
                    return 'Разблокировка карты';
                case this.activeOperationsCodes.pin:
                    return 'Сбросить кол-во попыток ввода пин кода'
            }
        },
        defaultHeader() {
            return this.activeOperationsType === this.activeOperationsCodes.block || this.activeOperationsType === this.activeOperationsCodes.unblock;
        },
        blockReasons() {
            return this.$store.state.activeOperations[this.card.typeName].reasons;
        },
    },
    methods: {
        close() {
            this.$refs.modal.close();
        },
    }
}
</script>
<style lang="scss">
@import '@/assets/scss/variables';
.active-operations-modal {
    &__header {
        padding-top: 38px;
        margin-bottom: -10px;
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        width: 302px;
        color: #000000;
    }
    &__subtitle {
        max-width: 343px;
        margin-left: auto;
        margin-right: auto;
        font-family: $font-secondary;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.005em;
        color: #949494;
    }
}
</style>
