<template>
    <div class="card-name">
        <div class="card-name__icon">
            <img :src="cardLogo" alt="">
        </div>
        <div>
            <div class="card-name__title">{{ $t("components.common.cardNumber") }}</div>
            <div class="card-name__value">{{ value }}</div>
        </div>
    </div>
</template>
<script>
import {getCardLogo} from "../../../config/logos";

export default {
    name: 'CardName',
    props: {
        value: String,
        type: String
    },
    computed: {
        cardLogo() {
            return getCardLogo(this.type);
        }
    }
}
</script>
<style lang="scss">
@import '@/assets/scss/variables';
.card-name {
    display: flex;
    align-items: center;
    &__icon {
        margin-right: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        background: #EDF2FF;
        border-radius: 8px;
        width: 72px;
        height: 48px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &__title {
        padding-left: 2px;
        font-family: $font-secondary;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: #949494;
    }
    &__value {
        font-weight: 500;
        font-size: 28px;
        line-height: 34px;
        color: #000000;
    }
}
</style>
